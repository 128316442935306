import { useEffect, useState } from 'react';
import { IServiceCard } from '../interfaces/components/Service';
import style from '../assets/scss/components/ServiceCard.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import InstrumentServicesDetails from './InstrumentServicesDetails';
import ClientServicesDetails from './ClientServicesDetails';
import { ServiceCardTypes } from '../constants/ServiceCard';
import { API_METHOD_POST } from '../constants/api';
import { SERVICE_ACTIVATE_API, SERVICE_DEACTIVATE_API } from '../constants/routes';
import { useApi } from '../hooks/useApi';
import { toast } from 'react-toastify';
import useGetTranslations from '../hooks/useGetTranslations';

function ServiceCard(props: IServiceCard): JSX.Element {
  const { service, onClick, type, showInstrumentData, showProjectIcon } = props;

  const { wrapper, bubble, bubbleOut, bubbleOutRed, bubbleContainer, shortLine, longLine, right, greenBubble } =
    convertKeysToCamelCase(style);
  const { handleApi } = useApi();
  const { translations } = useGetTranslations();

  const [isActive, setIsActive] = useState(service.isActive === 1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsActive(service.isActive === 1);
  }, [service.isActive]);

  const handleServiceActivate = async () => {
    if (isLoading) return;

    const optimisticValue = !isActive;
    setIsActive(optimisticValue);
    setIsLoading(true);

    const currentEndpoint = optimisticValue ? SERVICE_ACTIVATE_API : SERVICE_DEACTIVATE_API;

    try {
      const { code } = await handleApi(API_METHOD_POST, currentEndpoint.replace(':id', service.id.toString()));

      if (code !== 200) {
        throw new Error('API error');
      }
    } catch (error) {
      setIsActive(!optimisticValue);
      toast.error(translations.SomethingWentWong);
    } finally {
      setIsLoading(false);
    }
  };

  const checkPast = (date?: string) => {
    if (!date) return false;
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() - 1);
    const providedDate = new Date(date);
    return providedDate < tomorrow;
  };

  return (
    <div className={wrapper}>
      <div className={bubbleContainer}>
        <div className={shortLine} />
        <div className={checkPast(service.nextDate) ? bubbleOutRed : bubbleOut}>
          <div className={isActive ? greenBubble : bubble} onClick={() => onClick(service.id)} />
        </div>
        <div className={longLine} />
      </div>

      <div
        className={right}
        onClick={() => {
          onClick(service.id);
        }}
      >
        {type === ServiceCardTypes.CLIENT_SERVICE && (
          <ClientServicesDetails service={service} showProjectIcon={showProjectIcon} />
        )}

        {type === ServiceCardTypes.INSTRUMENT_SERVICE && (
          <InstrumentServicesDetails
            service={service}
            showInstrumentData={showInstrumentData}
            showProjectIcon={showProjectIcon}
            isActive={isActive}
            onToggleActive={handleServiceActivate}
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
}

export default ServiceCard;
