import { useSelector } from 'react-redux';
import { MdHistory } from 'react-icons/md';

import style from '../assets/scss/components/SearchHeader.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import BlueHeader from './BlueHeader';
import SortIcon from './SortIcon';
import FilterIcon from './FilterIcon';
import ActiveFilterHeader from './ActiveFilterHeader';
import { IReduxStore } from '../interfaces/IGeneral';
import GlobalSearch from './GlobalSearch';
import { IHomeSearchHeader } from '../interfaces/components/HomeSearchHeader';
import { useState } from 'react';
import useGlobalSearch from '../hooks/useGlobalSearch';

function HomeSearchHeader(props: IHomeSearchHeader): JSX.Element {
  const {
    hideSerachbar,
    hideFilters,
    hideSort,
    filters,
    sortTitle,
    sortField,
    isService,
    parentPage,
    passAllSortToActiveFilter,
  } = props;
  const { wrapper, topRow, historyRow, historyRowVisible, historyItem } = convertKeysToCamelCase(style);
  const { actualFilter, globalSearch, globalSearchHistory } = useSelector((store: IReduxStore) => store.defaultReducer);

  const [isHistoryOpen, setHistoryOpen] = useState(false);
  const { value, onValueChange, handleValue } = useGlobalSearch();

  return Object.keys(actualFilter).length === 0 ? (
    <BlueHeader
      element={
        <div className={wrapper}>
          <div className={topRow}>
            {!hideSort && (
              <SortIcon
                title={sortTitle}
                field={sortField}
                isService={isService}
                isInactive={Object.keys(globalSearch).length !== 0}
                parentPage={parentPage}
              />
            )}
            {!hideFilters && <FilterIcon filters={filters} isInactive={Object.keys(globalSearch).length !== 0} />}
            {!hideSerachbar && (
              <GlobalSearch value={value} onChange={onValueChange} onFocus={() => setHistoryOpen(true)} />
            )}
          </div>

          <div className={isHistoryOpen && globalSearchHistory.length > 0 ? historyRowVisible : historyRow}>
            {globalSearchHistory.map((item, index) => (
              <span
                className={historyItem}
                key={index}
                onClick={() => {
                  if (!isHistoryOpen) {
                    return;
                  }

                  handleValue(item);
                  setHistoryOpen(false);
                }}
              >
                <MdHistory />
                <p>{item}</p>
              </span>
            ))}
          </div>
        </div>
      }
      isSearchHistory={isHistoryOpen && globalSearchHistory.length > 0}
    />
  ) : (
    <ActiveFilterHeader
      title={sortTitle}
      field={sortField}
      isService={isService}
      parentPage={passAllSortToActiveFilter ? parentPage : undefined}
      actualFilter={actualFilter}
    />
  );
}

export default HomeSearchHeader;
