import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { useApi } from './useApi';
import { IReduxStore } from '../interfaces/IGeneral';
import { API_METHOD_GET } from '../constants/api';
import { ACTIVE_PROJECTS_LIST, PROJECTS_LIST } from '../constants/routes';
import { IProjectListElement, IProjectsInstruments, ISimpleProject } from '../interfaces/components/Project';
import { IUseGetProjects } from '../interfaces/components/Hooks';
import NoActiveSubscriptionNotification, {
  noActiveSubscriptionOptions,
} from '../components/NoActiveSubscriptionNotification';
import { SET_ACTIVE_PROJECTS, SET_ORDER, SET_PAGINATION } from '../constants/reduxActions';
import usePagination from './usePagination';
import { IClient } from '../interfaces/components/Clients';
import _ from 'lodash';
import { generateQueryParams } from '../helpers/baseHelper';

function useGetProjects(): IUseGetProjects {
  const { handleApi } = useApi();
  const [projects, setProjects] = useState<Array<IProjectListElement>>([]);
  const [projectsInstruments, setProjectsInstruments] = useState<IProjectsInstruments[]>([]);
  const [projectsClients, setProjectsClients] = useState<IClient[]>([]);
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();
  const { paginate } = usePagination();
  const [activeProjects, setActiveProjects] = useState<ISimpleProject[]>([]);

  // prettier-ignore
  const { actualFilter, order, pagination, clients, isActiveProjects } = useSelector(
    (store: IReduxStore) => store.defaultReducer
  );

  useEffect(() => {
    !isActiveProjects && dispatch({ type: SET_ORDER, payload: { orderByField: 'date', orderByDirection: 'DESC' } });
  }, []);

  useEffect(() => {
    if (order.orderByField === 'created_at') {
      if (isActiveProjects) {
        dispatch({ type: SET_ORDER, payload: { orderByField: 'ending_date', orderByDirection: 'ASC' } });
        return;
      }
      dispatch({ type: SET_ORDER, payload: { orderByField: 'date', orderByDirection: 'DESC' } });
      return;
    }
    if (order.orderByField !== 'created_at') {
      fetchData();
    }
  }, [order, actualFilter, isActiveProjects]);

  useEffect(() => {
    if ((actualFilter.noService as any) === true) {
      dispatch({ type: SET_ORDER, payload: { orderByField: 'id', orderByDirection: 'ASC' } });
      return;
    }
  }, [actualFilter]);

  useEffect(() => {
    if (order.orderByField !== 'created_at') {
      fetchDataWithPagination();
    }
  }, [pagination]);

  useEffect(() => {
    if (clients.length > 0 && ((projects.length > 0 && !isActiveProjects) || isActiveProjects)) {
      getProjectsInstruments();
    }
  }, [projects, clients, isActiveProjects]);

  async function fetchData() {
    if (isActiveProjects && !['starting_date', 'ending_date', 'brand'].includes(order.orderByField)) {
      return;
    }
    const answers = generateQueryParams(actualFilter);

    const x = `?hasService=${actualFilter.noService ? 0 : 1}&orderByField=${order.orderByField}&orderByDirection=${
      order.orderByDirection
    }${answers}&page=1&perPage=${pagination.perPage}`;
    const { data, code } = await handleApi(
      API_METHOD_GET,
      // eslint-disable-next-line prettier/prettier
      (isActiveProjects ? ACTIVE_PROJECTS_LIST : PROJECTS_LIST) + x
    );
    if (code !== 200) {
      showNoActiveSubscriptionToast();
      return;
    }
    isActiveProjects ? setActiveProjects(data.data) : setProjects(data.data);
    setTotal(data.total);
  }

  const fetchDataWithPagination = async () => {
    if (isActiveProjects && !['starting_date', 'ending_date', 'brand'].includes(order.orderByField)) {
      return;
    }
    const answers = generateQueryParams(actualFilter);

    const x = `?hasService=${actualFilter.noService ? 0 : 1}&orderByField=${order.orderByField}&orderByDirection=${
      order.orderByDirection
    }${answers}&page=${pagination.page}&perPage=${pagination.perPage}`;
    const { data, code } = await handleApi(
      API_METHOD_GET,
      // eslint-disable-next-line prettier/prettier
      (isActiveProjects ? ACTIVE_PROJECTS_LIST : PROJECTS_LIST) + x
    );
    if (code !== 200) {
      showNoActiveSubscriptionToast();
      return;
    }

    paginate(isActiveProjects ? setActiveProjects : setProjects, data.data);
    setTotal(data.total);
  };

  const getTotal = async () => {
    const { data, code } = await handleApi(
      API_METHOD_GET,
      // eslint-disable-next-line prettier/prettier
      isActiveProjects ? ACTIVE_PROJECTS_LIST : PROJECTS_LIST
    );
    if (code !== 200) {
      return;
    }
    return data.total;
  };

  const getProjectsInstruments = async () => {
    const total = await getTotal();

    const URL = (isActiveProjects ? ACTIVE_PROJECTS_LIST : PROJECTS_LIST) + `?page=1&perPage=${total}`;

    const { data, code } = await handleApi(API_METHOD_GET, URL);
    if (code !== 200) {
      return;
    }

    //prettier-ignore
    setProjectsInstruments(
      isActiveProjects ? _.unionBy(data.data.map((activeProject: ISimpleProject) => {
        return  {
          id: activeProject.instrument.id,
          brand: activeProject.instrument.brand,
          model: activeProject.instrument.model,
          category: activeProject.instrument.category
        }
      }), 'id') :
        data.data.map((project: IProjectsInstruments) => {
          return {
            id: project.id,
            brand: project.brand,
            model: project.model,
            category: project.category,
          };
        })
    );

    //prettier-ignore
    const allProjectsWithClient = isActiveProjects
      ? data.data.filter((project: ISimpleProject) => {
        return project.instrument?.client;
      })
      : data.data.filter((project: IProjectListElement) => {
        return project.client;
      });

    const allProjectsClients: IClient[] = [];

    if (isActiveProjects) {
      allProjectsWithClient.map((pr: ISimpleProject) => {
        const found = clients.find((client) => pr.instrument.client?.id === client.id);
        if (found) {
          allProjectsClients.push(found);
        }
      });
      setProjectsClients(_.sortBy(allProjectsClients, 'id'));
      return;
    }

    allProjectsWithClient.map((project: IProjectListElement) => {
      const found = clients.find((client) => project.client.id === client.id);
      if (found) {
        allProjectsClients.push(found);
      }
    });

    setProjectsClients(allProjectsClients);
  };

  const nextPage = () => {
    dispatch({ type: SET_PAGINATION, payload: { ...pagination, page: pagination.page + 1 } });
  };

  const showNoActiveSubscriptionToast = useCallback(() => {
    toast.error(<NoActiveSubscriptionNotification />, noActiveSubscriptionOptions);
  }, []);

  const handleActiveProjectsChecked = () => {
    if (!isActiveProjects) {
      dispatch({ type: SET_ORDER, payload: { orderByField: 'ending_date', orderByDirection: 'ASC' } });
      dispatch({ type: SET_ACTIVE_PROJECTS, payload: true });

      return;
    }
    dispatch({ type: SET_ORDER, payload: { orderByField: 'date', orderByDirection: 'DESC' } });
    dispatch({ type: SET_ACTIVE_PROJECTS, payload: false });
  };

  return {
    projects,
    total,
    projectsClients,
    projectsInstruments,
    nextPage,
    activeProjectsChecked: isActiveProjects,
    handleActiveProjectsChecked,
    activeProjects,
    setActiveProjects,
  };
}

export default useGetProjects;
