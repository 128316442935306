import { FilterFilter } from '../constants/filter';
import { IFilter, IFilterElement, IKeyString } from '../interfaces/IGeneral';
import { IDetailProject } from '../interfaces/components/Project';
import { ISuggestion } from '../interfaces/components/SuggestionSearch';

export function convertKeysToCamelCase(styleObject: IKeyString): IKeyString {
  const newStyleObject: IKeyString = {};
  Object.keys(styleObject).forEach((key) => {
    let newKey = key.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', ''));
    newKey = newKey.replace('-', '');

    newStyleObject[newKey] = styleObject[key];
  });

  return newStyleObject;
}

type Base64FileCallbackType = (fileString: string | ArrayBuffer | null, name: string) => void;
export const getBase64 = (file: File, l: Base64FileCallbackType): void => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    l(reader.result, file.name);
  };
};

export const handleMultiFileUpload = (files: FileList, callback: Base64FileCallbackType): void => {
  if (files) {
    for (let i = 0; i < files.length; i++) {
      getBase64(files[i], callback);
    }
  }
};

/*prettier-ignore*/
export const multiUploadFilesAndCheckLimit = (
  files: FileList | null,
  callback: Base64FileCallbackType,
  resourcesLeft?: number,
  onLimitExceeded?: () => void
) : void => {
  if (files) {
    if (resourcesLeft !== undefined && onLimitExceeded && files.length > resourcesLeft) {
      return onLimitExceeded();
    }
    handleMultiFileUpload(files, callback);
  }
};

export const toCamelCase = (s?: string): string => (s ? s.replace(/_([a-z])/g, (_, m) => m.toUpperCase()) : '');

export const dateToNumber = (date: string): number => {
  const dateSplit = date.split('-');
  const swap = dateSplit[1];
  dateSplit[1] = dateSplit[0];
  dateSplit[0] = swap;
  return Number(dateSplit.join(''));
};

export const capitalize = (word?: string): string => {
  if (!word) return '';
  return word[0].toLocaleUpperCase() + word.slice(1);
};

export const scrollToError = (errors: any): void => {
  const elements = Object.keys(errors)
    .map((name) => document.getElementsByName(name)[0])
    .filter((el) => !!el);
  elements.sort((a, b) => b.scrollHeight - a.scrollHeight);
  elements[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
};

export const buildUrl = (origin: string, route: string, params?: URLSearchParams): string => {
  const base = `${origin}${route}`;
  if (params) {
    return `${base}?${params.toString()}`;
  }
  return base;
};

export const filterDuplicateNames = (filters: IFilterElement[]): IFilterElement[] => {
  /*prettier-ignore*/
  return filters.filter(
    (value, index, self) => index === self.findIndex((t) => t.name === value.name)
  );
};

export const projectToSugesstion = (project: IDetailProject | undefined): ISuggestion | undefined => {
  if (project) {
    return {
      id: project.id,
      name: project.title,
    };
  }
};

// prettier-ignore
export const generateQueryParams = (actualFilter: IFilter) => {
  const keys = Object.keys(actualFilter) as Array<keyof IFilter>;

  return keys.map(
    (key: keyof IFilter) => 
      `&${[key]}=${FilterFilter[key] 
        ? (actualFilter[key]?.id ? actualFilter[key]?.id : true) 
        : encodeURIComponent(actualFilter[key]?.name as string)}`
  ).join('');
};
