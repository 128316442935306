import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { useApi } from './useApi';
import {
  SET_GLOBAL_SEARCH,
  SET_GLOBAL_SEARCH_HISTORY,
  SET_GLOBAL_SEARCH_ON_BACK,
  SET_GLOBAL_SEARCH_TEXT_VALUE,
  SET_RESET_GLOBAL_SEARCH,
} from '../constants/reduxActions';
import { API_METHOD_GET } from '../constants/api';
import { GLOBAL_SEARCH } from '../constants/routes';
import { IReduxStore } from '../interfaces/IGeneral';
import { IUseGlobalSearch } from '../interfaces/components/Hooks';

function useGlobalSearch(): IUseGlobalSearch {
  //prettier-ignore
  const { globalSearchTextValue, globalSearchOnBack, resetGlobalSearch } = useSelector(
    (store: IReduxStore) => store.defaultReducer
  );
  const [value, setValue] = useState(globalSearchTextValue);
  const [, setShouldUpdate] = useState(true);
  const [isDirectValue, setDirectValue] = useState(false);
  const { handleApi } = useApi();
  const dispatch = useDispatch();

  useEffect(() => {
    const storedHistory = JSON.parse(localStorage.getItem('searchHistory') || '[]');
    dispatch({ type: SET_GLOBAL_SEARCH_HISTORY, payload: storedHistory });
  }, []);

  useEffect(() => {
    if (resetGlobalSearch) {
      dispatch({ type: SET_RESET_GLOBAL_SEARCH, payload: false });
      dispatch({ type: SET_GLOBAL_SEARCH_ON_BACK, payload: false });
      setValue('');
      return;
    }

    if (globalSearchOnBack) {
      dispatch({ type: SET_GLOBAL_SEARCH_ON_BACK, payload: false });
      fetchSearch(value);
      return;
    }

    //prettier-ignore
    const delayDebounceFn = setTimeout(
      () => {
        fetchSearch(value);
      },
      isDirectValue ? 0 : 1500
    );

    return () => clearTimeout(delayDebounceFn);
  }, [value, resetGlobalSearch, isDirectValue]);

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setDirectValue(false);
  };

  const handleValue = (newValue: string) => {
    setValue(newValue);
    setDirectValue(true);
  };

  const updateSearchHistory = (newSearch: string) => {
    let storedHistory = JSON.parse(localStorage.getItem('searchHistory') || '[]');

    storedHistory = storedHistory.filter((item: string) => item !== newSearch);
    storedHistory.push(newSearch);
    storedHistory = _.takeRight(storedHistory, 3);

    localStorage.setItem('searchHistory', JSON.stringify(storedHistory));

    dispatch({ type: SET_GLOBAL_SEARCH_HISTORY, payload: storedHistory });
  };

  const fetchSearch = async (value: string) => {
    if (value && value.length > 2) {
      const { data } = await handleApi(API_METHOD_GET, GLOBAL_SEARCH + '?searchPhrase=' + value, {}, false);

      dispatch({ type: SET_GLOBAL_SEARCH, payload: data });
      dispatch({ type: SET_GLOBAL_SEARCH_TEXT_VALUE, payload: value });
      updateSearchHistory(value);
    } else {
      dispatch({ type: SET_GLOBAL_SEARCH, payload: {} });
      dispatch({ type: SET_GLOBAL_SEARCH_TEXT_VALUE, payload: '' });
    }
    setShouldUpdate(true);
  };

  return { value, onValueChange, handleValue };
}
export default useGlobalSearch;
