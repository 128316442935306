import SearchInput from './SearchInput';

interface GlobalSearchProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus: () => void;
}

function GlobalSearch({ value, onChange, onFocus }: GlobalSearchProps): JSX.Element {
  return <SearchInput value={value} onChange={onChange} onFocus={onFocus} />;
}
export default GlobalSearch;
