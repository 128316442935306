import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useApi } from './useApi';
import { IReduxStore } from '../interfaces/IGeneral';
import { API_METHOD_GET } from '../constants/api';
import { ISimpleService } from '../interfaces/components/Service';
import { ISimpleProject } from '../interfaces/components/Project';
import {
  CLIENT_SERVICES_API,
  CLIENT_PROJECTS_API,
  CLIENT_REMINDERS_API,
  REMINDER_DETAILS,
  INSTRUMENTS,
} from '../constants/routes';
import { ISuggestion } from '../interfaces/components/SuggestionSearch';
import {
  RESET_ACTUAL_FILTER,
  RESET_ORDER,
  SET_CLIENT_SERVICES_PAGE_SWITCHER,
  SET_ORDER,
  SET_PAGINATION,
} from '../constants/reduxActions';
import usePagination from './usePagination';
import { IUseClientServices } from '../interfaces/components/Hooks';
import useGeneral from './useGeneral';
import { IInstrument } from '../interfaces/components/Instrument';
import { generateQueryParams } from '../helpers/baseHelper';

function useClientsServices(): IUseClientServices {
  /* prettier-ignore */
  const { clients, order, pagination, actualFilter, clientServicesPageSwitcher } = useSelector(
    (store: IReduxStore) => store.defaultReducer
  );
  const { id } = useParams<{ id: string }>();
  const [switcherPage, setSwitcherPage] = useState(0);
  const [allServices, setAllServices] = useState<Array<ISimpleService>>([]);
  const [filteredServices, setFilteredServices] = useState<Array<ISimpleService>>([]);
  const [allProjects, setAllProjects] = useState<Array<ISimpleProject>>([]);
  const [filteredProjects, setFilteredProjects] = useState<Array<ISimpleProject>>([]);
  const [reminders, setReminders] = useState<Array<any>>([]);
  const [instruments, setInstruments] = useState<Array<ISuggestion>>([]);
  const [total, setTotal] = useState<number>(1);
  const { handleApi } = useApi();
  const dispatch = useDispatch();
  const { paginate } = usePagination();
  const { handleRedirect } = useGeneral();

  useEffect(() => {
    getInstruments();
  }, []);

  useEffect(() => {
    switch (switcherPage) {
      case 0:
        if (order.orderByField === 'created_at') {
          dispatch({ type: SET_ORDER, payload: { orderByField: 'last_date', orderByDirection: 'DESC' } });
          return;
        }
        fetchFilteredServices();
        break;
      case 1:
        fetchFilteredProjects();
        break;
      case 2:
        fetchReminders();
        break;
      default:
        break;
    }
    getInstruments();
  }, [order, actualFilter]);

  useEffect(() => {
    switch (switcherPage) {
      case 0:
        if (order.orderByField === 'created_at') {
          dispatch({ type: SET_ORDER, payload: { orderByField: 'last_date', orderByDirection: 'DESC' } });
          return;
        }
        fetchFilteredServicesWithPagination();
        // Object.keys(actualFilter).length > 0 ? setTotal(filteredServices.length) : setTotal(services.length);
        break;
      case 1:
        fetchFilteredProjectsWithPagination();
        // Object.keys(actualFilter).length > 0 ? setTotal(filteredProjects.length) : setTotal(projects.length);
        break;
      case 2:
        fetchRemindersWithPagination();
        break;
    }
  }, [pagination]);

  useEffect(() => {
    dispatch({ type: SET_PAGINATION, payload: { ...pagination, page: 1 } });
    dispatch({ type: RESET_ACTUAL_FILTER });
    dispatch({ type: RESET_ORDER });
    toggleSwitcherPage(clientServicesPageSwitcher);
  }, [clientServicesPageSwitcher]);

  useEffect(() => {
    getInstruments();
  }, [allServices]);

  async function fetchAllServices() {
    const query = `?page=1&perPage=99999`;
    const { data, code } = await handleApi(API_METHOD_GET, CLIENT_SERVICES_API.replace(':id', id) + query);
    if (code !== 200) {
      return;
    }

    setAllServices(data.data);
  }

  async function fetchFilteredServices() {
    const answers = generateQueryParams(actualFilter);

    const query = `?orderByField=${order.orderByField}&orderByDirection=${order.orderByDirection}${answers}&page=1&perPage=${pagination.perPage}`;
    const { data, code } = await handleApi(API_METHOD_GET, CLIENT_SERVICES_API.replace(':id', id) + query);
    if (code !== 200) {
      return;
    }

    setFilteredServices(data.data);
    setTotal(data.total);
  }

  async function fetchFilteredServicesWithPagination() {
    const answers = generateQueryParams(actualFilter);

    const query = `?orderByField=${order.orderByField}&orderByDirection=${order.orderByDirection}${answers}&page=${pagination.page}&perPage=${pagination.perPage}`;
    const { data, code } = await handleApi(API_METHOD_GET, CLIENT_SERVICES_API.replace(':id', id) + query);
    if (code !== 200) {
      return;
    }

    paginate(setFilteredServices, data.data);
    setTotal(data.total);

    fetchAllServices();
  }

  async function fetchAllProjects() {
    const query = `?page=1&perPage=99999`;
    const { data, code } = await handleApi(API_METHOD_GET, CLIENT_PROJECTS_API.replace(':id', id) + query);
    if (code !== 200) {
      return;
    }

    setAllProjects(data.data);
  }

  async function fetchFilteredProjects() {
    const answers = generateQueryParams(actualFilter);

    const query = `?orderByField=${order.orderByField}&orderByDirection=${order.orderByDirection}${answers}&page=1&perPage=${pagination.perPage}`;
    const { data, code } = await handleApi(API_METHOD_GET, CLIENT_PROJECTS_API.replace(':id', id) + query);
    if (code !== 200) {
      return;
    }
    setFilteredProjects(data.data);
    setTotal(data.total);
  }

  async function fetchFilteredProjectsWithPagination() {
    const answers = generateQueryParams(actualFilter);

    const query = `?orderByField=${order.orderByField}&orderByDirection=${order.orderByDirection}${answers}&page=${pagination.page}&perPage=${pagination.perPage}`;
    const { data, code } = await handleApi(API_METHOD_GET, CLIENT_PROJECTS_API.replace(':id', id) + query);
    if (code !== 200) {
      return;
    }

    paginate(setFilteredProjects, data.data);
    setTotal(data.total);

    fetchAllProjects();
  }

  async function fetchReminders() {
    const query = `?orderByField=created_at&orderByDirection=DESC&page=${pagination.page}&perPage=${pagination.perPage}`;
    const { data, code } = await handleApi(API_METHOD_GET, CLIENT_REMINDERS_API.replace(':id', id) + query);
    if (code !== 200) {
      return;
    }
    setReminders(data.data);
    setTotal(data.total);
  }

  async function fetchRemindersWithPagination() {
    const query = `?orderByField=created_at&orderByDirection=DESC&page=${pagination.page}&perPage=${pagination.perPage}`;
    const { data, code } = await handleApi(API_METHOD_GET, CLIENT_REMINDERS_API.replace(':id', id) + query);
    if (code !== 200) {
      return;
    }

    paginate(setReminders, data.data);
    setTotal(data.total);
  }

  const onReminderClick = (id: number) => {
    handleRedirect(REMINDER_DETAILS.replace(':id', id.toString()));
  };

  const toggleSwitcherPage = (index: number) => {
    dispatch({ type: SET_CLIENT_SERVICES_PAGE_SWITCHER, payload: index });
    setSwitcherPage(index);
  };

  const getInstruments = async () => {
    const { data } = await handleApi(API_METHOD_GET, INSTRUMENTS + `?clients=${id}&page=1&perPage=99999`);

    const transformedData: ISuggestion[] = data.data.map((instrument: IInstrument) => {
      return { id: instrument.id, name: instrument.brand };
    });

    setInstruments(transformedData);
  };

  const nextPage = () => {
    dispatch({ type: SET_PAGINATION, payload: { ...pagination, page: pagination.page + 1 } });
  };

  return {
    client: clients.find((cli) => cli.id.toString() === id),
    allServices,
    allProjects,
    switcherPage,
    toggleSwitcherPage,
    filteredServices,
    filteredProjects,
    instruments,
    nextPage,
    total,
    actualFilter,
    reminders,
    onReminderClick,
    setFilteredServices,
  };
}

export default useClientsServices;
