import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { useApi } from './useApi';
import { API_METHOD_GET } from '../constants/api';
import { IServiceListElement, IServicesInstrument, ISimpleService } from '../interfaces/components/Service';
import { ACTIVE_SERVICES_LIST, SERVICES_LIST } from '../constants/routes';
import { IReduxStore } from '../interfaces/IGeneral';
import NoActiveSubscriptionNotification, {
  noActiveSubscriptionOptions,
} from '../components/NoActiveSubscriptionNotification';
import { ADD_FILTER, SET_ACTIVE_SERVICES, SET_FILTER, SET_ORDER, SET_PAGINATION } from '../constants/reduxActions';
import usePagination from './usePagination';
import { IClient } from '../interfaces/components/Clients';
import { IUseGetServices } from '../interfaces/components/Hooks';
import _ from 'lodash';
import { generateQueryParams } from '../helpers/baseHelper';

function useGetServices(): IUseGetServices {
  const { handleApi } = useApi();
  const [services, setServices] = useState<IServiceListElement[]>([]);
  const [servicesInstruments, setServicesInstruments] = useState<IServicesInstrument[]>([]);
  const [servicesClients, setServicesClients] = useState<IClient[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();
  const { paginate } = usePagination();
  const [activeServices, setActiveServices] = useState<ISimpleService[]>([]);

  const DEFAULT_SERVICE_SORT = { orderByField: 'scheduledDate', orderByDirection: 'ASC' };
  const DEFAULT_ACTIVE_SERVICE_SORT = { orderByField: 'next_date', orderByDirection: 'ASC' };

  //prettier-ignore
  const { actualFilter, order, pagination, clients, hamburgerRef, isActiveServices } = useSelector(
    (store: IReduxStore) => store.defaultReducer
  );

  //TODO: get all services, projects and reminders, then generate servicesIntruments from that

  useEffect(() => {
    !isActiveServices && dispatch({ type: SET_ORDER, payload: DEFAULT_SERVICE_SORT });
  }, []);

  useEffect(() => {
    if (order.orderByField === 'created_at') {
      if (isActiveServices) {
        dispatch({ type: SET_ORDER, payload: DEFAULT_ACTIVE_SERVICE_SORT });
        return;
      }
      dispatch({ type: SET_ORDER, payload: DEFAULT_SERVICE_SORT });
      return;
    }
    if (order.orderByField !== 'created_at') {
      fetchData();
    }
  }, [order, actualFilter, isActiveServices]);

  useEffect(() => {
    if (order.orderByField !== 'created_at') {
      fetchDataWithPagination();
    }
  }, [pagination]);

  useEffect(() => {
    if (services.length > 0 && clients.length > 0) {
      getServicesInstrumentsAndClients();
    }
  }, [services, clients, isActiveServices]);

  useEffect(() => {
    if (hamburgerRef === null) {
      setToggle(false);
    }
  }, [hamburgerRef]);

  useEffect(() => {
    getServicesInstrumentsAndClients();
  }, [toggle, isActiveServices]);

  const handleToggle = () => {
    if (!toggle) {
      dispatch({ type: ADD_FILTER, payload: { hasUnfinishedService: true } });
      setToggle(true);
      return;
    }

    const restFilters = actualFilter;
    delete restFilters.hasUnfinishedService;

    dispatch({ type: SET_FILTER, payload: restFilters });
    setToggle(false);
  };

  async function fetchData() {
    if (isActiveServices && !['last_date', 'next_date', 'brand'].includes(order.orderByField)) {
      return;
    }
    if (!isActiveServices && order.orderByField === 'last_date') {
      return;
    }
    const answers = generateQueryParams(actualFilter);

    const x = `hasService=${actualFilter.noService ? 0 : 1}&orderByField=${order.orderByField}&orderByDirection=${
      order.orderByDirection
    }${answers}&page=1&perPage=${pagination.perPage}`;
    const { data, code } = await handleApi(
      API_METHOD_GET,
      // eslint-disable-next-line prettier/prettier
      (isActiveServices ? ACTIVE_SERVICES_LIST : SERVICES_LIST) + '?' + x
    );

    if (code !== 200) {
      showNoActiveSubscriptionToast();
      return;
    }
    isActiveServices ? setActiveServices(data.data) : setServices(data.data);
    setTotal(data.total);
  }

  const fetchDataWithPagination = async () => {
    if (isActiveServices && !['last_date', 'next_date', 'brand'].includes(order.orderByField)) {
      return;
    }
    if (!isActiveServices && order.orderByField === 'last_date') {
      return;
    }
    const answers = generateQueryParams(actualFilter);

    const x = `hasService=${actualFilter.noService ? 0 : 1}&orderByField=${order.orderByField}&orderByDirection=${
      order.orderByDirection
    }${answers}&page=${pagination.page}&perPage=${pagination.perPage}`;
    const { data, code } = await handleApi(
      API_METHOD_GET,
      // eslint-disable-next-line prettier/prettier
      (isActiveServices ? ACTIVE_SERVICES_LIST : SERVICES_LIST) + '?' + x
    );

    if (code !== 200) {
      showNoActiveSubscriptionToast();
      return;
    }
    paginate(isActiveServices ? setActiveServices : setServices, data.data);
    setTotal(data.total);
  };

  const getServicesInstrumentsAndClients = async () => {
    const URL =
      (isActiveServices ? ACTIVE_SERVICES_LIST : SERVICES_LIST) + `?hasService=${toggle ? 0 : 1}&page=1&perPage=999999`;

    const { data, code } = await handleApi(API_METHOD_GET, URL);
    if (code !== 200) {
      return;
    }

    //prettier-ignore
    setServicesInstruments( isActiveServices ? data.data.map((activeService: ISimpleService) => {
      return {
        id: activeService.instrumentId,
        brand: activeService.instrument?.brand,
        model:  activeService.instrument?.model,
        category: activeService.instrument?.category,
        hasService: true,
      }
    }) :
      data.data
        // .filter((instrument: IServiceListElement) => instrument.hasService !== toggle)
        .map((service: IServiceListElement) => {
          return {
            id: service.id,
            brand: service.brand,
            model: service.model,
            category: service.category,
            hasService: service.hasService,
          };
        })
    );

    //prettier-ignore
    const allServicesWithClient = isActiveServices ? data.data.filter((as: ISimpleService) => {return  as.instrument?.client}) : data.data.filter(
      (service: IServiceListElement) => service.client && service.hasService !== toggle
    );
    const allServicesClients: IClient[] = [];

    if (isActiveServices) {
      allServicesWithClient.map((as: ISimpleService) => {
        const found = clients.find((client) => as.instrument?.client?.id === client.id);
        if (found) {
          allServicesClients.push(found);
        }
      });
      setServicesClients(_.sortBy(allServicesClients, 'id'));
      return;
    }

    allServicesWithClient.map((service: IServiceListElement) => {
      const found = clients.find((client) => service.client.id === client.id);
      if (found) {
        allServicesClients.push(found);
      }
    });

    setServicesClients(_.uniqBy(allServicesClients, 'id'));
  };

  const nextPage = () => {
    dispatch({ type: SET_PAGINATION, payload: { ...pagination, page: pagination.page + 1 } });
  };

  const showNoActiveSubscriptionToast = useCallback(() => {
    toast.error(<NoActiveSubscriptionNotification />, noActiveSubscriptionOptions);
  }, []);

  const handleActiveServiceChecked = () => {
    if (!isActiveServices) {
      dispatch({ type: SET_ORDER, payload: DEFAULT_ACTIVE_SERVICE_SORT });
      dispatch({ type: SET_ACTIVE_SERVICES, payload: true });
      return;
    }
    dispatch({ type: SET_ORDER, payload: DEFAULT_SERVICE_SORT });
    dispatch({ type: SET_ACTIVE_SERVICES, payload: false });
  };

  return {
    services: [...services],
    servicesClients,
    servicesInstruments,
    total,
    toggle,
    nextPage,
    handleToggle,
    activeServiceChecked: isActiveServices,
    handleActiveServiceChecked,
    activeServices,
    setActiveServices,
    getServicesInstrumentsAndClients,
  };
}

export default useGetServices;
