import style from '../assets/scss/components/BlueHeader.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { IBlueHeader } from '../interfaces/components/BlueHeader';

function BlueHeader({ element, isWhiteBackground, isSearchHistory }: IBlueHeader): JSX.Element {
  const { wrapper, row, whiteWrapper, searchWrapper } = convertKeysToCamelCase(style);

  return (
    <div className={isWhiteBackground ? whiteWrapper : isSearchHistory ? searchWrapper : wrapper}>
      <section className={`${row} small`}>{element}</section>
    </div>
  );
}

export default BlueHeader;
