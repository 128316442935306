import EditHeader from '../EditHeader';
import useServiceDetail from '../../hooks/useServiceDetail';
import InstrumentCard from '../InstrumentCard';
import ServiceDetailForm from '../ServiceDetailForm';
import AdSection from '../AdSection';
import useSetHeader from '../../hooks/useSetHeader';
import useGetTranslation from '../../hooks/useGetTranslations';
import IdComponent from '../IdComponent';
import { convertKeysToCamelCase, projectToSugesstion } from '../../helpers/baseHelper';
import { MenuBar } from '../MenuBar';
import { MdPiano } from 'react-icons/md';
import { CLIENT_DETAILS, INSTRUMENT_DETAILS, INSTRUMENT_SERVICES, NEW_CLIENT_PAGE } from '../../constants/routes';
import { BsFillPersonFill } from 'react-icons/bs';
import { toast } from 'react-toastify';
import LimitExceededNotification, { limitExceededOptions } from '../LimitExceededNotification';
import useMySubscriptionPlan from '../../hooks/useMySubscriptionPlan';
import useGeneral from '../../hooks/useGeneral';
import ReactToggle from 'react-toggle';
import style from '../../assets/scss/components/Toggle.module.scss';
import { GiAutoRepair } from 'react-icons/gi';
import { SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER } from '../../constants/reduxActions';
import { useDispatch } from 'react-redux';
import { BiCalendar } from 'react-icons/bi';

function ServiceDetail(): JSX.Element {
  const {
    instrument,
    service,
    register,
    handleSubmit,
    onPictureChange,
    onAttachmentChange,
    handleDelete,
    errors,
    attachmentNr,
    pictureNr,
    handleLastDate,
    handleNextDate,
    lastServiceDate,
    nextServiceDate,
    handleDeleteLastDate,
    handleDeleteNextDate,
    handleNewProjectNameChange,
    newProjectName,
    handleExistingProjectChange,
    project,
    toggleEditing,
    handleServiceActivate,
    tuner,
    newTunerName,
    handleTunerChange,
    handleNewTunerNameChange,
  } = useServiceDetail();
  const { translations } = useGetTranslation();
  const { canCreateNewClient } = useMySubscriptionPlan();
  const { handleRedirect } = useGeneral();
  const { custom, flex, activeText, activeContainer } = convertKeysToCamelCase(style);
  const dispatch = useDispatch();

  //prettier-ignore
  useSetHeader(
    () => {},
    project ? translations.serviceDetailTitleWithProject : translations.serviceDetailTitleWithoutProject
  );

  return (
    <main>
      <EditHeader
        isEditing={false}
        toggleEditing={toggleEditing}
        handleDelete={handleDelete}
        deleteTitle={translations.deleteServiceDescription}
        deleteButtonText={translations.deleteService}
      />
      <AdSection topPadding>
        <IdComponent id={service?.id} text={translations.serviceId} />
        <div className={flex}>
          <InstrumentCard {...instrument} isSimple={true} noMargin />
          <div className={activeContainer}>
            <p className={activeText}>{translations.active}</p>
            <ReactToggle className={custom} onChange={handleServiceActivate} checked={service?.isActive === 1} />
          </div>
        </div>
        <MenuBar
          items={[
            {
              icon: <MdPiano />,
              onClick: () => {
                if (!service) {
                  return;
                }
                handleRedirect(INSTRUMENT_DETAILS.replace(':id', service.instrumentId.toString()));
              },
            },
            {
              icon: <BsFillPersonFill />,
              onClick: () => {
                if (!service) {
                  return;
                }

                if (!service.instrument.client || service.instrument.client === null) {
                  if (!canCreateNewClient()) {
                    toast.error(<LimitExceededNotification />, limitExceededOptions);
                    return;
                  }
                  handleRedirect(NEW_CLIENT_PAGE + `?instrumentId=${service.instrumentId}`);
                  return;
                } else {
                  handleRedirect(CLIENT_DETAILS.replace(':id', service.instrument.client.id.toString()));
                }
              },
            },
            {
              icon: <GiAutoRepair />,
              onClick: () => {
                if (!instrument || !instrument.id) {
                  return;
                }
                dispatch({ type: SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER, payload: 0 });
                handleRedirect(INSTRUMENT_SERVICES.replace(':id', instrument.id.toString()));
              },
            },
            {
              icon: <BiCalendar />,
              onClick: () => {
                if (!instrument || !instrument.id) {
                  return;
                }
                dispatch({ type: SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER, payload: 2 });
                handleRedirect(INSTRUMENT_SERVICES.replace(':id', instrument.id.toString()));
              },
            },
          ]}
        />
        <ServiceDetailForm
          register={register}
          handleSubmit={handleSubmit}
          isEditing={false}
          service={service}
          onMatch={handleExistingProjectChange}
          onAttachmentChange={onAttachmentChange}
          onPictureChange={onPictureChange}
          errors={errors}
          pictureNr={pictureNr}
          attachmentNr={attachmentNr}
          lastServiceDate={lastServiceDate}
          nextServiceDate={nextServiceDate}
          handleLastDate={handleLastDate}
          handleNextDate={handleNextDate}
          showLeaveWarning={true}
          handleDeleteLastDate={handleDeleteLastDate}
          handleDeleteNextDate={handleDeleteNextDate}
          handleNewProjectNameChange={handleNewProjectNameChange}
          newProjectName={newProjectName}
          project={projectToSugesstion(project)}
          handleNewTunerNameChange={handleNewTunerNameChange}
          handleTunerMatch={handleTunerChange}
          newTunerName={newTunerName}
          tuner={tuner}
        />
      </AdSection>
    </main>
  );
}

export default ServiceDetail;
